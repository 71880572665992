<template>
  <form
    class="partner-search-ctrl js-partner-search-ctrl"
    novalidate
    @submit="handleSubmitEvent"
  >
    <div :class="getCtrlFieldCSSClasses">
      <text-input
        id="employerSearchField"
        ref="partnerSearchField"
        input-name="search"
        :autofocus="true"
        :autocomplete="false"
        :placeholder="$t(inputFieldTextKey)"
        :disabled="ctrlItemDisabled"
        :state="true"
        role="textbox"
        :aria-label="$t(inputFieldTextKey)"
        data-q-a="employer-search-field"
        :loading="loading"
        :maxlength="200"
        content-desc="search-field"
        @focus="openDropdown"
        @click.stop
        @keyup="handleTextInputChange"
      />
      <div
        v-if="showSearchDropdownList"
        ref="partnerSearchDropdown"
        class="js-ctrl-dropdown partner-search-ctrl__dropdown-layout"
      >
        <div
          v-for="(partner, index) in searchResultsComputed"
          :key="partner.key"
          :class="getDropdownItemCSSClasses(index)"
          :data-qa="'employer-search-option-' + partner.key"
          @click="handleSearchDropdownItemClick(partner)"
          v-html="partner.titleView"
        />
      </div>
    </div>
    <div
      v-if="error"
      class="partner-search-ctrl__error"
    >
      {{ error }}
    </div>
    <div class="partner-search-ctrl__button">
      <BaseButton
        :text="$t('generic_check_coverage')"
        data-qa="employer-search-submit"
        :loading="interactiveButtonState === 'PROCESSING'"
        :success="interactiveButtonState === 'VALIDATED'"
        @click="handleBaseButtonClick"
      />
    </div>
  </form>
</template>

<script>
import normalSearchHighlight from '@shared/helpers/normalSearchHighlight'
import abbreviationSearchHighlight from '@shared/helpers/abbreviationSearchHighlight'
import TextInput from '@shared/components/TextInput.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import { CanceledError } from 'axios'
import Tracker from '@shared/Tracker'

const ARROW_KEY_UP = 38
const ARROW_KEY_DOWN = 40
const ENTER = 13

const INVALIDATED_PARTNER = {
  id: -1,
  title: '',
  titleView: '',
  key: 'INVALIDATED_PARTNER',
  is_customer: false,
}

const getHighlightedTitle = (needle, title) =>
  normalSearchHighlight(needle, title) ||
  abbreviationSearchHighlight(needle, title) ||
  title

const getSearchedPartner = (suggestions, searchValue) => {
  const isSearchedPartner = (suggestion) => {
    if (suggestion.title.toLowerCase() === searchValue) {
      return true
    }
  }
  const partnerIndex = suggestions.findIndex(isSearchedPartner)
  return suggestions[partnerIndex]
}

export default {
  name: 'PartnerSearchCtrl',
  components: {
    BaseButton,
    TextInput,
  },
  props: {
    pendingResults: {
      type: Object,
      default: () => Promise.resolve('null'),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    corporateKey: {
      type: String,
      default: () => '',
    },
    inputFieldTextKey: {
      type: String,
      default: 'generic_employer_name',
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['search', 'ctrlStateChange'],
  data() {
    return {
      interactiveButtonState: 'ACTIVE',
      nextValue: '',
      showSearchDropdownList: false,
      selectedPartner: null,
      highlightedPartnerIndex: -1,
      suggestions: [],
    }
  },
  computed: {
    searchResultsComputed() {
      if (!Array.isArray(this.suggestions)) {
        return []
      }
      return this.suggestions
        .filter(
          (suggestion) =>
            suggestion.key &&
            suggestion.title &&
            suggestion.key.length > 0 &&
            suggestion.title.length > 0,
        )
        .map((suggestion) =>
          Object.assign(suggestion, {
            titleView: getHighlightedTitle(this.nextValue, suggestion.title),
          }),
        )
    },
    ctrlItemDisabled() {
      return (
        this.interactiveButtonState === 'PROCESSING' ||
        this.interactiveButtonState === 'VALIDATED'
      )
    },
    getCtrlFieldCSSClasses() {
      return [
        'partner-search-ctrl__field',
        this.ctrlItemDisabled ? 'partner-search-ctrl__field--disabled' : '',
        this.error ? 'partner-search-ctrl__field--error' : '',
      ]
    },
  },
  watch: {
    suggestions() {
      this.openDropdown()
    },
    async pendingResults() {
      try {
        const results = await this.pendingResults
        this.suggestions = results.data.corporates
      } catch (error) {
        if (error instanceof CanceledError) {
          // expected
        } else {
          throw error
        }
      }
    },
  },
  mounted() {
    if (this.corporateKey) {
      this.$refs.partnerSearchField.value = this.corporateKey
      this.nextValue = this.corporateKey
      this.interactiveButtonState = 'ACTIVE'
      this.handleBaseButtonClick()
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleDocumentClickEvent)
    document.removeEventListener('keydown', this.handleDocumentKeyDownEvent)
  },
  methods: {
    handleTextInputChange(event) {
      if (
        event.keyCode === ENTER ||
        event.keyCode === ARROW_KEY_DOWN ||
        event.keyCode === ARROW_KEY_UP
      ) {
        return
      }

      this.suggestions = []

      const input = event.target.value.trim().toLowerCase()
      this.$emit('ctrlStateChange', { state: 'active' })
      if (this.nextValue !== input) {
        this.$emit('search', input)
      }
      this.nextValue = input

      this.interactiveButtonState = 'ACTIVE'
    },
    handleSearchDropdownItemClick(partner) {
      this.selectedPartner = partner
      this.$refs.partnerSearchField.value = partner.title
      this.handleBaseButtonClick()
    },
    handleBaseButtonClick() {
      this.interactiveButtonState = 'PROCESSING'
      this.$emit('ctrlStateChange', { state: 'processing' })

      this.pendingResults.then(() => {
        const currentSearchValue =
          this.$refs.partnerSearchField.value.toLowerCase()
        const searchedPartner = getSearchedPartner(
          this.suggestions,
          currentSearchValue,
        )

        Tracker.trackKaiaEvent(
          {
            event_name: 'c.onbrdg.select_employer_search',
            app_area: 'onboarding',
            action: 'select',
            object_type: 'employer_search',
            source: 'client_browser',
            screen_name: 'onb_employer_search',
          },
          {
            custom_payload: {
              search_value: currentSearchValue,
              search_success: !!searchedPartner,
            },
          },
        )

        if (searchedPartner) {
          this.selectedPartner = searchedPartner
          this.validateSelectedPartner(this.selectedPartner)
        } else {
          this.interactiveButtonState = 'ACTIVE'
          this.$emit('ctrlStateChange', {
            state: 'disabled',
            value: currentSearchValue,
          })
        }
      })
    },
    validateSelectedPartner(partner) {
      const selectedPartner = partner == null ? INVALIDATED_PARTNER : partner

      setTimeout(() => {
        if (selectedPartner.is_customer) {
          this.interactiveButtonState = 'VALIDATED'
          setTimeout(() => {
            this.$emit('ctrlStateChange', {
              state: 'validated',
              value: selectedPartner,
            })
          }, 1000)
        } else {
          this.interactiveButtonState = 'ACTIVE'
          this.$emit('ctrlStateChange', {
            state: 'disabled',
            value: selectedPartner,
          })
        }
      }, 500)
    },
    handleDocumentClickEvent() {
      this.showSearchDropdownList = false
      this.highlightedPartnerIndex = -1
      document.removeEventListener('keydown', this.handleDocumentKeyDownEvent)
    },
    handleDocumentKeyDownEvent(event) {
      let nextScrollTop = 0
      if (event.keyCode === ARROW_KEY_DOWN) {
        const nextIndex = this.highlightedPartnerIndex + 1
        this.highlightedPartnerIndex =
          nextIndex <= this.searchResultsComputed.length - 1 ? nextIndex : 0

        nextScrollTop =
          this.$refs.partnerSearchDropdown.children[
            this.highlightedPartnerIndex
          ].offsetTop
        this.$refs.partnerSearchDropdown.scrollTop = nextScrollTop
      } else if (event.keyCode === ARROW_KEY_UP) {
        const nextIndex = this.highlightedPartnerIndex - 1
        this.highlightedPartnerIndex =
          nextIndex < 0 ? this.searchResultsComputed.length - 1 : nextIndex

        nextScrollTop =
          this.$refs.partnerSearchDropdown.children[
            this.highlightedPartnerIndex
          ].offsetTop
        this.$refs.partnerSearchDropdown.scrollTop = nextScrollTop
      } else if (event.keyCode === ENTER) {
        event.preventDefault()

        const nextPartner =
          this.searchResultsComputed[this.highlightedPartnerIndex]
        if (nextPartner != null) {
          this.handleSearchDropdownItemClick(nextPartner)
          this.handleDocumentClickEvent(event)
        }
      }
    },
    openDropdown() {
      this.showSearchDropdownList = this.searchResultsComputed.length > 0

      if (this.showSearchDropdownList) {
        this.highlightedPartnerIndex = 0
        document.addEventListener('click', this.handleDocumentClickEvent)
        document.addEventListener('keydown', this.handleDocumentKeyDownEvent)
      } else {
        this.highlightedPartnerIndex = -1
        this.selectedPartner = null

        document.removeEventListener('keydown', this.handleDocumentKeyDownEvent)
      }
    },
    getDropdownItemCSSClasses(partnerIndex) {
      return [
        'partner-search-ctrl__dropdown-layout__item',
        'js-ctrl-dropdown__item',
        this.highlightedPartnerIndex === partnerIndex
          ? 'partner-search-ctrl__dropdown-layout__item--selected js-ctrl-dropdown__item-selected'
          : '',
      ]
    },
    handleSubmitEvent(event) {
      if (
        !!this.suggestions[0] &&
        this.suggestions[0].title.toLowerCase() === this.nextValue.toLowerCase()
      ) {
        ;[this.selectedPartner] = this.suggestions
      }
      this.handleBaseButtonClick()
      event.preventDefault()
    },
  },
}
</script>

<style lang="scss">
.partner-search-ctrl {
  &__dropdown-layout {
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    border: 2px solid $gray-15;
    box-shadow:
      0 2px 2px rgba(10, 28, 64, 0.1),
      0 2px 4px rgba(10, 28, 64, 0.1);
    background-color: $white;
    box-sizing: border-box;
    padding: 4px 0;

    &__item {
      padding: 10px 0;
      padding-left: 15px;
      cursor: pointer;

      &:hover,
      &--selected {
        background-color: $gray-5;
      }
    }
  }

  &__field {
    position: relative;

    &--disabled {
      opacity: 0.7;
    }

    .form-field {
      margin-bottom: $kds-space-8x;
    }

    .form-field__control {
      box-shadow: 0 0 0 2px $kds-color-line-on-interactive;
      background-color: $kds-color-background-base;

      &.form-field--is-active {
        box-shadow: 0 0 0 2px $kds-color-line-focus;
      }
    }

    &--error {
      .form-field__control {
        box-shadow: 0 0 0 2px $kds-color-text-interactive-destructive !important;
      }
    }

    .form-field__input {
      padding: 12px 16px 12px 52px;
      color: $midnight;
      font-weight: 700;

      &::placeholder {
        color: $midnight;
        opacity: 1;
        font-weight: 400;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 18px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      background: url('@shared/assets/img/b2b/icon-search.svg') center no-repeat;
    }
  }

  &__error {
    @include kds-typography-ui-strong-small;
    color: $kds-color-text-interactive-destructive;
    text-align: start;
  }

  &__button {
    display: inline-block;
    width: 100%;
    margin-top: $kds-space-2_5x;
    text-align: center;

    .base-button {
      width: 100%;
    }
  }

  &__dropdown-layout {
    position: absolute;
    top: 105%;
    left: 0;
    z-index: 1;
  }
}
</style>
