<template>
  <div class="responsive-layout">
    <div
      :class="['responsive-layout__header', showTopShadow ? 'show_scroll' : '']"
    >
      <!-- TODO move to component -->
      <div class="responsive-layout__header--header-component">
        <div class="start">
          <slot name="header_start">
            <div
              class="responsive-layout__header--header-component--kaia-logo"
            />
          </slot>
        </div>
        <div class="middle"><slot name="header_middle"></slot></div>
        <div class="end">
          <slot name="header_end">
            <LanguageSwitcher
              v-if="showLangPicker"
              short
            />
            <GenericSupportModal v-if="showGetHelp">
              <template #trigger="{ open }">
                <BaseButton
                  variant="ghost"
                  size="small"
                  @click="open"
                >
                  <template #prepend>
                    <QuestionMarkIcon />
                  </template>
                  <p
                    class="kds-typography-interactive-medium kds-color-text-interactive-accent"
                  >
                    {{ 'Help' }}
                  </p>
                </BaseButton>
              </template>
            </GenericSupportModal>
          </slot>
        </div>
      </div>
    </div>
    <div
      ref="contentWrapper"
      class="responsive-layout__content-wrapper"
      @scroll="debouncedHandleContentWrapperScroll"
    >
      <div class="responsive-layout__content-wrapper--content">
        <slot />
      </div>
    </div>
    <div
      v-if="$slots.actions"
      :class="[
        'responsive-layout__actions',
        showBottomShadow ? 'show_scroll' : '',
      ]"
    >
      <slot name="actions"> </slot>
    </div>
    <div class="responsive-layout__bottom" />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { debounce } from '@shared/utils.js'
import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import GenericSupportModal from '@msk-us/components/GenericSupportModal.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import QuestionMarkIcon from '@shared/assets/icon/QuestionMarkIcon.vue'

defineProps({
  showGetHelp: {
    type: Boolean,
    default: true,
  },
  showLangPicker: {
    type: Boolean,
    default: true,
  },
})

const contentWrapper = ref(null)
const showTopShadow = ref(false)
const showBottomShadow = ref(false)

const handleContentWrapperScroll = () => {
  showTopShadow.value = contentWrapper.value.scrollTop > 5
  showBottomShadow.value =
    contentWrapper.value.scrollHeight -
      contentWrapper.value.scrollTop -
      contentWrapper.value.clientHeight >
    5
}

const debouncedHandleContentWrapperScroll = debounce(
  handleContentWrapperScroll,
  5,
)

onMounted(() => {
  handleContentWrapperScroll()
  window.addEventListener('resize', debouncedHandleContentWrapperScroll)
})
onUnmounted(() => {
  window.removeEventListener('resize', debouncedHandleContentWrapperScroll)
})
</script>

<style scoped lang="scss">
.responsive-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-up(md) {
    background-color: $kds-color-background-subtle;
  }

  &__header {
    width: 100%;
    background-color: $kds-color-background-base;
    flex-shrink: 0;
    z-index: 1;

    &.show_scroll {
      box-shadow: 0 1.5px 0 0 $kds-color-background-interactive-subtle-hover;
    }

    // TODO move to component
    &--header-component {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $kds-space-2_5x;
      gap: $kds-space-1x;
      height: 46px;

      .start,
      .middle,
      .end {
        display: flex;
      }
      .middle {
        flex-grow: 1;
      }

      &--kaia-logo {
        background-image: url('@shared/assets/img/kaia_text_logo.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 14px;
        width: 100px;

        @include media-up(md) {
          height: $kds-space-3x;
          width: 145px;
        }
      }

      @include media-up(md) {
        max-width: 796px;
        margin: 0 auto;
        height: 70px;
      }
    }
  }

  &__content-wrapper {
    flex-grow: 1;
    overflow-y: auto;
    padding: $kds-space-2_5x;

    @include media-up(md) {
      padding: 0;
      flex-grow: 0;
    }

    &--content {
      @include media-up(md) {
        width: calc($size-medium - $kds-space-3x);
        box-sizing: border-box;
        margin: $kds-space-4x auto 0 auto;
        padding: $kds-space-4x $kds-space-6x $kds-space-2x $kds-space-6x;
        border-radius: $kds-shape-rounded-large $kds-shape-rounded-large 0 0;
        background-color: $kds-color-background-base;
      }
    }
  }

  &__actions {
    padding: $kds-space-2_5x;
    background-color: $kds-color-background-base;
    position: sticky;
    bottom: 0;

    &.show_scroll {
      box-shadow: 0 -1.5px 0 0 $kds-color-background-interactive-subtle-hover;
    }

    @include media-up(md) {
      width: calc($size-medium - $kds-space-3x);
      box-sizing: border-box;
      margin: 0 auto 0 auto;
      padding: $kds-space-2x $kds-space-6x;
      background-color: $kds-color-background-base;
    }
  }

  &__bottom {
    @include media-up(md) {
      width: calc($size-medium - $kds-space-3x);
      height: $kds-space-4x;
      flex-grow: 0;
      box-sizing: border-box;
      margin: 0 auto $kds-space-4x auto;
      border-radius: 0 0 $kds-shape-rounded-large $kds-shape-rounded-large;
      background-color: $kds-color-background-base;
    }
  }
}
</style>
