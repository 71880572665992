import ROUTE from '@msk-us/router/names'

export default [
  {
    path: '/b2b',
    name: ROUTE.B2B,
    component: () => import('@msk-us/views/B2B.vue'),
  },
  {
    path: '/b2b/partners',
    name: ROUTE.B2B_PARTNERS,
    component: () => import('@msk-us/views/B2BPartners.vue'),
    meta: {
      name: 'onb_employer_search',
    },
  },
  {
    path: '/uhc_vpt_employer_search',
    name: ROUTE.UHC_VPT_EMPLOYER_SEARCH,
    component: () => import('@msk-us/views/UhcVptEmployerSearch.vue'),
    meta: {
      name: 'uhc_vpt_employer_search',
      recordSession: true,
    },
  },
  {
    path: '/uhc_vpt_insurance_selection',
    name: ROUTE.UHC_VPT_INSURANCE_SELECTION,
    component: () => import('@msk-us/views/UhcVptInsuranceSelection.vue'),
    meta: {
      name: 'uhc_vpt_insurance_selection',
      recordSession: true,
    },
  },
  {
    path: '/uhc_vpt_primer',
    name: ROUTE.UHC_VPT_PRIMER,
    component: () => import('@msk-us/views/UhcVptPrimer.vue'),
    meta: {
      name: 'uhc_vpt_primer',
      recordSession: true,
    },
  },
  {
    path: '/b2b/information',
    name: ROUTE.B2B_INFORMATION_GENERIC,
    component: () => import('@msk-us/views/B2BLandingPage.vue'),
    meta: {
      layout: 'B2BLandingLayout',
    },
  },
  {
    path: '/b2b/:corp_name',
    name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
  },
  {
    path: '/b2b/:corp_name/voucher/:voucherCode?',
    name: ROUTE.B2B_VOUCHER,
    component: () => import('@msk-us/views/B2BVoucher.vue'),
  },
  {
    path: '/b2b/:corp_name/email/:emailCode?',
    name: ROUTE.B2B_EMAIL,
    component: () => import('@msk-us/views/B2BEmail.vue'),
  },
  // TODO think about making all other routes that resolve to the eligibility verification view
  // aliases to this route (instead of separate routes)
  {
    path: '/b2b/:corp_name/eligibility_verification',
    name: ROUTE.B2B_CORPORATE_ELIGIBILITY_VERIFICATION,
    component: () =>
      import('@msk-us/views/B2BCorporateEligibilityVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/eligibility_list',
    name: ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
    component: () =>
      import('@msk-us/views/B2BCorporateEligibilityVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/participation-id',
    name: ROUTE.B2B_PARTICIPATION_ID_VERIFICATION,
    component: () =>
      import('@msk-us/views/B2BCorporateEligibilityVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/solera-verification',
    name: ROUTE.B2B_SOLERA_VERIFICATION,
    component: () => import('@msk-us/views/SoleraVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/uhchub-verification',
    name: ROUTE.B2B_UHCHUB_VERIFICATION,
    component: () => import('@msk-us/views/UhchubVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/b2b/:corp_name/sign-up',
    name: ROUTE.B2B_SIGN_UP,
    component: () => import('@msk-us/views/B2BSignUp.vue'),
    meta: {
      name: 'onb_account_creation',
    },
  },
  {
    path: '/b2b/:corp_name/login',
    name: ROUTE.B2B_LOGIN,
    component: () => import('@msk-us/views/B2BLogin.vue'),
    meta: {
      name: 'onb_signin',
    },
  },
  {
    path: '/b2b/:corp_name/download-link',
    name: ROUTE.B2B_DOWNLOAD_LINK,
    component: () => import('@msk-us/views/DownloadLink.vue'),
    meta: {
      layout: 'B2BDownloadLayout',
      name: 'onb_download_prompt',
    },
  },
  {
    path: '/b2b/:corp_name/download-instructions',
    redirect: '/b2b/:corp_name/download-link',
  },
  {
    path: '/b2b/:corp_name/virginpulse-sso',
    name: ROUTE.B2B_VIRGINPULSE_SSO_INITIATED,
    component: () => import('@msk-us/views/B2BVirginpulseSSOInitiated.vue'),
  },
  {
    path: '/b2b/:corp_name/sso',
    name: ROUTE.B2B_VIRGINPULSE_SSO_COMPLETED,
    component: () => import('@msk-us/views/B2BVirginpulseSSOCompleted.vue'),
  },
  {
    path: '/b2b/:corp_name/sso-error',
    name: ROUTE.B2B_VIRGINPULSE_SSO_ERROR,
    component: () => import('@msk-us/views/B2BVirginpulseSSOError.vue'),
  },
  {
    path: '/b2b/:corp_name/information',
    name: ROUTE.B2B_INFORMATION,
    meta: {
      layout: 'B2BLandingTestLayout',
      recordSession: true,
    },
  },
  {
    path: '/b2b/:corp_name/information/check-in',
    name: ROUTE.B2B_SIMPLE_CHECK_IN,
    component: () => import('@msk-us/views/B2BSimpleLandingPage.vue'),
    meta: {
      layout: 'B2BSimpleLandingLayout',
      recordSession: true,
    },
  },
  {
    path: '/b2b/:corp_name/information/plan-outline',
    name: ROUTE.B2B_SIMPLE_PLAN_OUTLINE,
    component: () => import('@msk-us/views/B2BSimpleLandingPage.vue'),
    meta: {
      layout: 'B2BSimpleLandingLayout',
      recordSession: true,
    },
  },
  {
    path: '/b2b/:corp_name/information/testimonial',
    name: ROUTE.B2B_SIMPLE_TESTIMONIAL,
    component: () => import('@msk-us/views/B2BSimpleLandingPage.vue'),
    meta: {
      layout: 'B2BSimpleLandingLayout',
      recordSession: true,
    },
  },
  {
    path: '/b2b/:corp_name/subscribe-not-possible',
    name: ROUTE.B2B_VERIFY_NOT_POSSIBLE_FOR_EXISTING_USER,
    component: () =>
      import('@msk-us/views/B2BVerifyNotPossibleForExistingUser.vue'),
  },
  ...(import.meta.env.VITE_MODE === 'prod'
    ? []
    : [
        {
          path: '/uhchub/init',
          name: ROUTE.B2B_UHCHUB_INIT,
          component: () => import('@msk-us/views/UhchubInit.vue'),
        },
      ]),
]
