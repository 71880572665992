<template>
  <ResponsiveLayout>
    <div class="virginpulse-sso-content">
      <Spinner v-html="$t('b2b__SSO__redirecting_to_virginpulse')" />
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { useStore } from 'vuex'
import Spinner from '@shared/components/Spinner.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import { computed } from 'vue'

const store = useStore()
const corporate = computed(() => store.getters['b2b/corporate'])

const connectWithVirginpulse = () => {
  window.location.href = `${
    import.meta.env.VITE_API_BASE_URL
    // somehow the VP entrypoint is not wrapped in the API under /v2/
  }/auth/saml?resource_class=User`
}

const connectWithMockedIDP = () => {
  if (window.location.hostname === 'localhost') {
    window.location.href = `${
      import.meta.env.VITE_API_BASE_URL
    }/v2/dev_auth/saml?resource_class=User`
  } else {
    window.location.href = `${
      import.meta.env.VITE_API_BASE_URL
    }/v2/fake_auth/saml?resource_class=User&redirect_host=${
      window.location.hostname
    }`
  }
}

if (corporate.value.key === 'mocked-idp') {
  connectWithMockedIDP()
} else {
  connectWithVirginpulse()
}
</script>

<style lang="scss">
.virginpulse-sso-content {
  height: 100vh;

  .spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
</style>
