<template>
  <!-- TODO refactor towards a generic SSO error page -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <div class="content-header">
        <p
          class="kds-typography-display-small"
          v-html="$t('b2b__virginpulse_signup_error__title')"
        />
      </div>
      <div
        class="instructions"
        v-html="errorMessage"
      />
      <BaseButton
        :text="$t('generic__try_again')"
        @click="redirectToStart"
      />
      <div
        class="instructions"
        v-html="
          $t(
            'b2b__virginpulse_signup_error__contact_support_with_support_email',
            {
              VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL,
            },
          )
        "
      />
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { useStore } from 'vuex'
import env from '@shared/env'
import ROUTE from '@msk-us/router/names'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import { useI18n } from 'petite-vue-i18n'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const corporate = computed(() => store.getters['b2b/corporate'])

const errorMessage = computed(() => {
  switch (this.$route.query?.code) {
    case 'UNAUTHORIZED':
      return t('b2b__virginpulse_signup_error__unauthorized')
    case 'UNKNOWN_SPONSOR_ID':
      return t('b2b__virginpulse_signup_error__unknown_sponsor_id')
    case 'NO_ACTIVE_SUBSCRIPTION':
      return t('b2b__virginpulse_signup_error__no_active_subscription')
    case 'DATA_ERROR':
    case 'SERVICE_ERROR':
    default:
      return t('b2b__virginpulse_signup_error__unknown_error')
  }
})

const redirectToStart = () => {
  router.push({
    name: ROUTE.B2B_INFORMATION,
    params: {
      corp_name: corporate.value.key,
    },
  })
}
</script>

<style lang="scss">
.virginpulse-sso-error-content {
  .content-header {
    margin-bottom: 32px;
  }

  .instructions {
    color: $input-placeholder-color;
    margin-bottom: 32px;
  }

  .btn {
    margin-bottom: 32px;
  }
}
</style>
